import PropTypes from "prop-types"
import React, { useState } from "react"
import CountUp from "react-countup"
import { Helmet } from "react-helmet"
import ModalVideo from "react-modal-video"
import VisibilitySensor from "react-visibility-sensor"
import Layout from "../components/layout"
import Videoplaceholder from "../images/AnimatedExplainerVideos.png"
import Videoplaceholdertwo from "../images/CampaignVideos.png"
import Videoplaceholderthree from "../images/CustomerTestimonialsVideos.png"
import Videoplaceholderfive from "../images/Demo.png"
import Videoplaceholderfour from "../images/SocialMediaVid.png"
import Videoplaceholdersix from "../images/TeaserVideos.png"
import VideoMarRightRoundOne from "../images/VideoMarRightRoundOne.png"
import Shapefour from "../images/bannerElement/white-design-element-four.svg"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import Discovery from "../images/icons/VideoMarketing/Discovery.svg"
import Illustrations from "../images/icons/VideoMarketing/Illustrations.svg"
import PostProduction from "../images/icons/VideoMarketing/PostProduction.svg"
import Scripting from "../images/icons/VideoMarketing/Scripting.svg"
import Storyboarding from "../images/icons/VideoMarketing/Storyboarding.svg"
import VOMusic from "../images/icons/VideoMarketing/VO-Music.svg"
import image1 from "../images/image-one.svg"
import image3 from "../images/image-three.svg"
import image2 from "../images/image-two.svg"
import VideoMarRightRoundTwo from "../images/strategicdistribution.png"

import "../styles/global-styles.css"
import VideoBanner from "../video/video-marketing-banner-video.mp4"

const VideoMarketing = () => {
  const [countUpVisible, setCountUpVisible] = useState(false)
  const handleVisibilityChange = isVisible => {
    if (isVisible) {
      setCountUpVisible(true)
    }
  }
  const [isOpen, setOpen] = useState([false, false, false]) // Add more booleans if you have more cards
  const openModal = index => {
    let newIsOpen = [...isOpen]
    newIsOpen[index] = true
    setOpen(newIsOpen)
  }
  const closeModal = index => {
    let newIsOpen = [...isOpen]
    newIsOpen[index] = false
    setOpen(newIsOpen)
  }
  const cards = [
    {
      videoId: "924573882", // replace with your video id
      title: "Animated Explainer Videos",
      text: "Engage your audience with our energetic, animated explainer videos that capture attention and spark imagination so your viewers are informed, entertained, and eager to take action.",
      img1: Videoplaceholder,
    },
    {
      videoId: "924576895", // replace with your video id
      title: "Campaign Videos",
      text: "Make an unforgettable impact with creative campaign videos that capture attention, clarify your position, and drive your message home.",
      img1: Videoplaceholdertwo,
    },
    {
      videoId: "924581898", // replace with your video id
      title: "Customer Testimonials Videos",
      text: "Let your happy and satisfied customers do the talking and notice how the voices of real, satisfied customers convince potential buyers that you deliver on your promises.",
      img1: Videoplaceholderthree,
    },
    {
      videoId: "924582916", // replace with your video id
      title: "Social Media Videos",
      text: "Cut through the noise with bite-sized videos that bring your message to life in fun and engaging ways perfectly suited for TikTok, YouTube Shorts, and Instagram Reels that stop scrollers in their tracks.",
      img1: Videoplaceholderfour,
    },
    {
      videoId: "927511548", // replace with your video id
      title: "Demo/ 'How to' Videos",
      text: 'Our "how to" videos visually walk your customers through processes, products, and systems simply and straightforwardly. Now, your customers can learn how to do anything with just the click of a play button!',
      img1: Videoplaceholderfive,
    },
    {
      videoId: "924591239", // replace with your video id
      title: "Teaser Videos",
      text: "Generate buzz and excitement with compelling teaser videos that hint at what's coming up. Create teasers for upcoming product launches, event announcements, and new feature reveals. Leave your audience wanting more!",
      img1: Videoplaceholdersix,
    },
  ]
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Video Marketing | Position²</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body className="home" />
      </Helmet>
      <Layout>
        <div class="video-marketing content-creative">
          <section id="Banner">
            <div class="bgVideo">
              <video
                width="100%"
                playsinline="playsinline"
                muted="muted"
                preload="yes"
                autoplay="autoplay"
                loop="loop"
                class="video-js"
                data-setup='{"controls": false, "autoplay": true, "preload": "true", "loop":true }'
              >
                <source src={VideoBanner} type="video/mp4" />
              </video>
            </div>
            <div class="bannerContent">
              <div class="container">
                <div class="banner-holder">
                  <div class="images">
                    <div className="img-one image-shape">
                      <img src={Shapeone} alt="Image" />
                    </div>
                    <div className="img-two image-shape">
                      <img src={Shapetwo} alt="Image" />
                    </div>
                    <div className="img-three image-shape">
                      <img src={Shapethree} alt="Image" />
                    </div>
                    <div className="img-four image-shape">
                      <img src={Shapefour} alt="Image" />
                    </div>
                  </div>
                  <div class="title">
                    <div class="banner-title">
                      <h1>Videos that work</h1>
                      <p>
                        We make videos that engage users, go viral and generate
                        leads.
                      </p>
                      <a className="button" href="/contact-us">
                        Get in touch
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="TextImageSection">
            <div class="container">
              <div class="text-image-holder">
                <div class="text-wrap">
                  <h2>
                    Visually captivating solutions to your creative problems
                  </h2>
                  <p>
                    Video Marketing is the most compelling of marketing tools.
                    Video content has the power to boost your conversion by 80%.
                    90% of marketing giants say video content is integral to
                    their growth strategy. At Position<sup>2</sup>, our video
                    marketing solutions leverage the power of sight, sound and
                    motion to captivate viewers and spur them to action.{" "}
                  </p>
                  <ul class="countUp">
                    <li>
                      <VisibilitySensor onChange={handleVisibilityChange}>
                        <div class="count-wrap">
                          <CountUp
                            end={countUpVisible ? 500 : 0}
                            duration={3}
                          />
                          <span class="plus">+</span>
                        </div>
                      </VisibilitySensor>
                      Videos created
                    </li>
                    <li>
                      <VisibilitySensor onChange={handleVisibilityChange}>
                        <div class="count-wrap">
                          <CountUp end={countUpVisible ? 2 : 0} duration={3} />
                          <span class="plus">M+</span>
                        </div>
                      </VisibilitySensor>
                      Impressions
                    </li>
                    <li>
                      <VisibilitySensor onChange={handleVisibilityChange}>
                        <div class="count-wrap">
                          <CountUp end={countUpVisible ? 5 : 0} duration={3} />
                          <span class="plus">B+</span>
                        </div>
                      </VisibilitySensor>
                      Reach
                    </li>
                  </ul>
                  <a
                    id="3dsolutionvideo"
                    target="_blank"
                    href="https://www.position2studios.com/videos"
                    class="video-marketing"
                  >
                    View video portfolio
                  </a>
                </div>
                <div class="img-wrap">
                  <img
                    alt="Video Marketing"
                    width="500"
                    height="500"
                    src={VideoMarRightRoundOne}
                  />
                </div>
              </div>
            </div>
          </section>
          <section id="ImgVideoDesCard">
            <div class="container">
              <h2 class="title">What can we do for you</h2>
              <p>
                We create videos that enable you to achieve your targeted goals
                at every stage of the marketing campaign.
              </p>
              <ul className="cards">
                {cards.map((card, index) => (
                  <li className="cards__item" key={index}>
                    <div className="card">
                      <div className="card__image">
                        <img
                          alt="Video Placeholder"
                          width="398"
                          height="296"
                          src={card.img1}
                        />
                        <ModalVideo
                          channel="vimeo"
                          isOpen={isOpen[index]}
                          videoId={card.videoId}
                          onClose={() => closeModal(index)}
                        />
                        <button onClick={() => openModal(index)}>
                          <i className="ptwo ptwo-playicon"></i>
                        </button>
                        <div className="overlay"></div>
                      </div>
                      <div className="card__content">
                        <div className="card__title">{card.title}</div>
                        <p className="card__text">{card.text}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              {/* <ul class="cards">
                <li class="cards__item">                
                  <div class="card">
                    <div class="card__image">
                      <img
                        alttext="Video Placeholder"
                        width="398"
                        height="296"
                        src={Videoplaceholder}
                      />
                      <button onClick={() => setOpen1(true)}>
                        <i class="ptwo ptwo-playicon"></i>View samples{" "}
                      </button>
                      <div class="overlay"></div>
                      <ModalVideo
                        channel="vimeo"
                        autoplay
                        isOpen1={isOpen1}
                        videoId="806751187"
                        onClose={() => setOpen1(false)}
                      />
                    </div>
                    <div class="card__content">
                      <div class="card__title">Animated Explainer Videos</div>
                      <p class="card__text">
                        Engage your audience with our energetic, animated
                        explainer videos that capture attention and spark
                        imagination so your viewers are informed, entertained,
                        and eager to take action.
                      </p>
                    </div>
                  </div>
                </li>
                <li class="cards__item">
                  <div class="card">
                    <div class="card__image">
                      <img
                        alttext="Video Placeholder"
                        width="398"
                        height="296"
                        src={Videoplaceholder}
                      />
                      <button onClick={() => setOpen2(true)}>
                        <i class="ptwo ptwo-playicon"></i>View samples{" "}
                      </button>
                      <div class="overlay"></div>
                      <ModalVideo
                        channel="vimeo"
                        autoplay
                        isOpen2={isOpen2}
                        videoId="806751187"
                        onClose={() => setOpen2(false)}
                      />
                    </div>
                    <div class="card__content">
                      <div class="card__title">Campaign Videos</div>
                      <p class="card__text">
                        Make an unforgettable impact with creative campaign
                        videos that capture attention, clarify your position,
                        and drive your message home.
                      </p>
                    </div>
                  </div>
                </li>
                <li class="cards__item">
                  <div class="card">
                    <div class="card__image">
                      <img
                        alttext="Video Placeholder"
                        width="398"
                        height="296"
                        src={Videoplaceholder}
                      />
                      <button>
                        <i class="ptwo ptwo-playicon"></i>View samples{" "}
                      </button>
                      <div class="overlay"></div>
                    </div>
                    <div class="card__content">
                      <div class="card__title">
                        Customer Testimonials Videos
                      </div>
                      <p class="card__text">
                        Let your happy and satisfied customers do the talking
                        and notice how the voices of real, satisfied customers
                        convince potential buyers that you deliver on your
                        promises.
                      </p>
                    </div>
                  </div>
                </li>
                <li class="cards__item">
                  <div class="card">
                    <div class="card__image">
                      <img
                        alttext="Video Placeholder"
                        width="398"
                        height="296"
                        src={Videoplaceholder}
                      />
                      <button>
                        <i class="ptwo ptwo-playicon"></i>View samples{" "}
                      </button>
                      <div class="overlay"></div>
                    </div>
                    <div class="card__content">
                      <div class="card__title">Social Media Videos</div>
                      <p class="card__text">
                        Cut through the noise with bite-sized videos that bring
                        your message to life in fun and engaging ways perfectly
                        suited for TikTok, YouTube Shorts, and Instagram Reels
                        that stop scrollers in their tracks.
                      </p>
                    </div>
                  </div>
                </li>
                <li class="cards__item">
                  <div class="card">
                    <div class="card__image">
                      <img
                        alttext="Video Placeholder"
                        width="398"
                        height="296"
                        src={Videoplaceholder}
                      />
                      <button>
                        <i class="ptwo ptwo-playicon"></i>View samples{" "}
                      </button>
                      <div class="overlay"></div>
                    </div>
                    <div class="card__content">
                      <div class="card__title">Demo/ ‘How to’ Videos</div>
                      <p class="card__text">
                        Our "how to" videos visually walk your customers through
                        processes, products, and systems simply and
                        straightforwardly. Now, your customers can learn how to
                        do anything with just the click of a play button!
                      </p>
                    </div>
                  </div>
                </li>
                <li class="cards__item">
                  <div class="card">
                    <div class="card__image">
                      <img
                        alttext="Video Placeholder"
                        width="398"
                        height="296"
                        src={Videoplaceholder}
                      />
                      <button>
                        <i class="ptwo ptwo-playicon"></i>View samples{" "}
                      </button>
                      <div class="overlay"></div>
                    </div>
                    <div class="card__content">
                      <div class="card__title">Teaser Videos</div>
                      <p class="card__text">
                        Generate buzz and excitement with compelling teaser
                        videos that hint at what's coming up. Create teasers for
                        upcoming product launches, event announcements, and new
                        feature reveals. Leave your audience wanting more!
                      </p>
                    </div>
                  </div>
                </li>
              </ul> */}
            </div>
          </section>
          <section id="TextImageSection2">
            <div class="container">
              <div class="text-image-holder">
                <div class="text-wrap">
                  <h2>Strategic Distribution for powerful results</h2>
                  <p>
                    We optimize your customer journey for maximum conversions.
                    Our team analyzes the path from awareness to purchase to
                    determine where video can influence and motivate. We use
                    targeted video content tailored to each stage that drives
                    your audience through the funnel. We continually optimize
                    content based on engagement data and refine messaging to
                    ensure your content inspires action at every touchpoint.
                  </p>
                  <p>
                    <span>Proven Results</span> - More viewers become qualified
                    leads, primed advocates, and loyal customers.
                  </p>
                </div>
                <div class="img-wrap">
                  <img
                    alt="Video Marketing"
                    width="500"
                    height="500"
                    src={VideoMarRightRoundTwo}
                  />
                </div>
              </div>
            </div>
          </section>
          <section id="Process">
            <div class="container">
              <h2>Our process involves AI</h2>
              <div class="multiStageProcess">
                <div class="eachProcess">
                  <div class="iconImage">
                    <img width="100" height="100" src={Discovery} />
                    <span class="borderImg">
                      <img width="214" height="161" src={image1} />
                    </span>
                  </div>
                  <div class="processContent">Discovery</div>
                </div>
                <div class="eachProcess">
                  <div class="iconImage">
                    <img width="100" height="100" src={Scripting} />
                    <span class="borderImg">
                      <img width="214" height="161" src={image2} />
                    </span>
                  </div>
                  <div class="processContent">Scripting</div>
                </div>
                <div class="eachProcess">
                  <div class="iconImage">
                    <img width="100" height="100" src={Storyboarding} />
                    <span class="borderImg">
                      <img width="214" height="161" src={image2} />
                    </span>
                  </div>
                  <div class="processContent">Storyboarding</div>
                </div>
                <div class="eachProcess">
                  <div class="iconImage">
                    <img width="100" height="100" src={Illustrations} />
                    <span class="borderImg">
                      <img width="214" height="161" src={image2} />
                    </span>
                  </div>
                  <div class="processContent">Illustrations</div>
                </div>
                <div class="eachProcess">
                  <div class="iconImage">
                    <img width="100" height="100" src={VOMusic} />
                    <span class="borderImg">
                      <img width="214" height="161" src={image2} />
                    </span>
                  </div>
                  <div class="processContent">VO &amp; Music</div>
                </div>
                <div class="eachProcess">
                  <div class="iconImage">
                    <img width="100" height="100" src={PostProduction} />
                    <span class="borderImg">
                      <img width="160" height="161" src={image3} />
                    </span>
                  </div>
                  <div class="processContent">Post-production</div>
                </div>
              </div>
            </div>
          </section>
          <div id="InnerSlider" className="home-testimonial">
            <div className="slider-wrapper">
              <div className="inner-slider">
                <div className="each-slider">
                  <div className="banner-cover">
                    <video
                      width="100%"
                      playsInline="playsinline"
                      muted="muted"
                      preload="yes"
                      autoPlay="autoplay"
                      loop="loop"
                      className="video-js"
                      data-setup="{'controls': false, 'autoplay': true, 'preload': true, 'loop':true }"
                    >
                      <source
                        src="https://www.position2.com/footer-video.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <div className="slider-content">
                    <div className="container">
                      <div className="content-wrap">
                        {/* <p className="eyebrow">Case Study</p> */}
                        <h2>Lenovo's new product line unveiled</h2>
                        <p>
                          During the pandemic, Lenovo partnered with us to
                          launch their new products. We made a series of videos
                          featuring SMB owners in LA using Lenovo's products,
                          effectively positioning Lenovo as a top choice for
                          SMBs.
                        </p>
                        <a
                          target="_blank"
                          href="https://www.position2studios.com/campaigns"
                        >
                          View all videos
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section id="contactus-section">
            <div class="container">
              <h2>Let’s create something beautiful.</h2>
              <a className="button" href="/contact-us">
                Get in touch
              </a>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

VideoMarketing.propTypes = {
  siteTitle: PropTypes.string,
}

VideoMarketing.defaultProps = {
  siteTitle: ``,
}

export default VideoMarketing
